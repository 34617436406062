import Moment from 'moment';
import { emptyTask, Task, TaskExample } from "../interfaces/Task";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';

interface TaskItemProps {
    task: TaskExample;
    duplicateCallback: CallableFunction;
}

export default function TaskItemExample(props: TaskItemProps) {
    const navigate = useNavigate();

    const [task, setTask] = useState<Task>(emptyTask);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + props.task.task._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                if (data.aims === undefined) {
                    data.aims = [];
                }
                if (data.proficiency_level === undefined) {
                    data.proficiency_level = [];
                }
                setTask(data);
            });
    }, []);

    
    const handleDuplicateTask = () => {
        const newTask = Object.assign({}, task);
        newTask._id = '';
        newTask.name = props.task.task.name + ' (copy)';
        newTask.userId = localStorage.getItem('userId') ?? '';
        newTask.clonned = true;
        newTask.original_source = 'example';
        newTask.original_task = props.task._id;
        
        // Create a new task
        const requestOptionsPost = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newTask)
        };
        fetch('https://srv.taskgen.eu/v1/task', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                props.duplicateCallback(data);
            });
    }

    return(
        <div className="task-item teaser" key={ 'task-' + props.task.task._id }>
            <div className="title">
                { props.task.task.name }
            </div>
            <div className="meta">
                <div className="changed">
                    { 'Last updated' }: <span>{ Moment(props.task.task.updated).format('DD/MM/YYYY') }</span>
                </div>
                <div className="actions">
                    <button className="no-decoration duplicate" title="Duplicate" onClick={ handleDuplicateTask }></button>
                </div>
            </div>
        </div>
    );
}