import { Button, Form } from "react-bootstrap";
import { emptyOrg } from "../../interfaces/Org";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import parse from 'html-react-parser';
import { obscureEmail } from "../../../utils/utils";

export interface TaskExampleAddProps {
    submitCallback: CallableFunction;
    closeCallback: CallableFunction;
}

export default function TaskExampleAdd(props: TaskExampleAddProps) {
    const navigate = useNavigate();

    const [tasksFound, setTasksFound] = useState<any[]>([]);
    const [taskSelected, setTaskSelected] = useState(null);
    const [processing, setProcessing] = useState(false);
    
    const handleClose = () => {
        props.closeCallback();
    }

    const handleSubmit = (event: any) => {
        event.preventDefault();
        let error = false;
        let error_messages: string[] = [];
        
        if (error) {
            alert(error_messages.join('\n'));
        } else {
            const requestOptionsPost = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify({
                    taskSelected: taskSelected
                })
            };
            fetch('https://srv.taskgen.eu/v1/examples/add', requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    props.closeCallback();      
                });
        }
        return false;
    }

    const handleTasksSearch = (event: any) => {
        if (event.target.value.length < 3) {
            setTasksFound([]);
        } else {
            setProcessing(true);
            let reqUrl = 'https://srv.taskgen.eu/v1/tasks/search/';
            let queryParams: string[] = [];
            queryParams.push('search=' + event.target.value);
            const requestOptionsPost = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            };
            reqUrl += '?' + queryParams.join('&');
            fetch(reqUrl, requestOptionsPost)
                .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                })
                .then(data => {
                    const newTasksFound = data.filter((tf: any) => tf.userId !== undefined && tf.userId != null).map((t: any) => {
                        let userlabel = obscureEmail(t.userId.email);
                        if (t.userId.profile.firstname || t.userId.profile.lastname) {
                            const label_array: string[] = [];
                            if (t.userId.profile.firstname) {
                                label_array.push(t.userId.profile.firstname);
                            }
                            if (t.userId.profile.lastname) {
                                label_array.push(t.userId.profile.lastname);
                            }
                            userlabel = label_array.join(' ');
                        }
                        return {
                            id: t._id,
                            label: t.name + ' (' + userlabel + ')'
                        }
                    });
                    setTasksFound(newTasksFound);
                    setProcessing(false);
                });
        }
    }

    const handleSelectTask = (event: any) => {
        const id = event.target.value;
        const checked = event.target.checked;
        if (checked) {
            setTaskSelected(id);
        }
    }

    return(
        <div className="overlay">
            <div className="inner">
                <div className="overlay-content">
                    <a className="close" onClick={ handleClose }></a>
                    <div className="admin-add-taskexamples-container">
                        <h4>{ 'Add new Task Example' }</h4>
                        <div className="taskexample-form-container">
                            <Form onSubmit={ handleSubmit }>
                            <div className="share-options-container">
                                <div className="search">
                                    <Form.Control type="text" placeholder="Search tasks..." onChange={ handleTasksSearch } />
                                    { processing && <div className="processing"></div> }
                                </div>
                                { tasksFound.length > 0 && 
                                    <div className="tasks">
                                        <div className="found">
                                            <div className="label">
                                            { parse('Select the tasks you want to add as examples:') }
                                            </div>
                                            <ul>
                                                { tasksFound.map((t: any) => {
                                                    return <li key={ `tasksFound-${t.id}` }>
                                                        <input type="radio" name="taskSelected" value={ t.id } onChange={ handleSelectTask } />
                                                        <span>
                                                            { t.label } <a className={ 'select-task' + (taskSelected !== undefined && taskSelected == t.id ? ' selected' : '') } onClick={ handleSelectTask } id={ `taskFound-${t.id}`}></a>
                                                        </span>
                                                    </li>;
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                                <div className="actions">
                                    <Button type="submit" variant="primary">{ 'Add selected tasks' }</Button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    );
}