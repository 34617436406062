import { useState } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Password from "../elements/Password";
import mailIcon from "../../assets/img/mail.svg";

export default function LoginEmailForm() {
    const navigate =  useNavigate();
    const [showPasswordResetForm, setShowPasswordResetForm] = useState(false);
    const [working, setWorking] = useState(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (showPasswordResetForm) {
            setWorking(true);
            const requestOptionsPost = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email: event.target.email.value
                })
            };
            fetch('https://srv.taskgen.eu/v1/user/password', requestOptionsPost)
                .then(response => response.json())
                .then(data => {
                    setWorking(false);
                    if (data.status == 'ok') {
                        alert('A new password has been sent to your inbox. You can change your password in your profile once you log in.');
                    } else {
                        alert('There was a problem resetting your password. Please try again later or write to us at info@taskgen.eu.');
                    }
                    setShowPasswordResetForm(false);
                });
        } else {
            const requestOptionsGet = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username: event.target.email.value,
                    password: event.target.password.value
                })
            };
            fetch('https://srv.taskgen.eu/v1/user/login', requestOptionsGet)
                .then(response => response.json())
                .then(data => {
                    if (data.status == 'ok') {
                        const userData = data.userData;
                        const token = data.token;
                        localStorage.setItem('token', token);
                        localStorage.setItem('userId', userData._id);
                        localStorage.setItem('userRole', userData.role);
                        localStorage.setItem('userEmail', userData.email);
                        localStorage.setItem('userLevel', '');
                        const isAdmin = userData.role && (userData.role == 'admin' || userData.role.includes('admin'));
                        localStorage.setItem('isAdmin', isAdmin);
                        
                        if (userData.profile.firstname == '' || userData.profile.lastname == '' ||
                            userData.privacy_policy === undefined || userData.privacy_policy == false ||
                            userData.profile.teaching_location.length == 0 || userData.profile.teaching_model.length == 0 ||
                            userData.profile.country == '' || userData.profile.region == '' || userData.profile.teaching_years == null ||
                            userData.profile.teaching_framework.length == 0) {
                            navigate('/first-login');
                        } else {
                            navigate('/dashboard');
                        }
                    } else {
                        alert('Usuario o contraseña incorrecta');
                    }
                });
        }
    }

    const handleResetPassword = () => {
        setShowPasswordResetForm(true);
    }

    return(
        <Form className="form-login" onSubmit={ handleSubmit }>
            { !showPasswordResetForm &&
                <p>Log in using your email</p>
            }
            { showPasswordResetForm &&
                <p>Write your email and we will send you a new password.</p>
            }
            <div className="field email">
                <img src={ mailIcon } alt=""/>
                <input type="email" name="email" placeholder="Email Address" disabled={ working } />
            </div>
            { !showPasswordResetForm &&
                <div className="field">
                    <Password/> 
                    { !showPasswordResetForm &&
                        <div className="reset-password">
                            { 'You don\'t remember your password?' }
                            <a onClick={ handleResetPassword } title={ 'Reset your password' }>
                                { 'Reset it' }
                            </a>
                        </div>
                    }
                </div>
            }
            <div className="actions">
                { !showPasswordResetForm &&
                    <input className="submit btn btn-primary" type="submit" value='Login' required/>
                }
                { showPasswordResetForm &&
                    <input className="submit btn btn-primary" type="submit" value='Reset password' disabled={ working } required />
                }
            </div>
            { working && 
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            }
        </Form>         
    );
}