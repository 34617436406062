import { useEffect, useState } from "react";
import { ButtonGroup, Dropdown, DropdownButton, Form, FormControl, FormLabel } from "react-bootstrap";
import PrimaryHeader from "../header/PrimaryHeader";
import SecondaryHeader from "../header/SecondaryHeader";
import { PosTask, PreTask, SubTask, SubTaskGroup, SubTaskPrompt, SubTaskRole, Task, aimsOfTask, proficiencyLevelsOfTask } from "../interfaces/Task";
import NewPreTaskText from "./PreTaskTypes/NewPreTaskText";
import PreTaskTeaser from "./PreTaskTeaser";
import NewPreTaskAudio from "./PreTaskTypes/NewPreTaskAudio";
import SubTaskTeaser from "./SubTask/SubTaskTeaser";
import Help from "../elements/HelpContent";
import { useParams } from "react-router-dom";
import NewPreTaskVideo from "./PreTaskTypes/NewPreTaskVideo";
import NewPreTaskDialog from "./PreTaskTypes/NewPreTaskDialog";
import NewPreTaskPhoto from "./PreTaskTypes/NewPreTaskPhoto";
import NewPreTaskSpeech from "./PreTaskTypes/NewPreTaskSpeech";
import NewPosTaskExplanationText from "./PosTaskTypes/NewPosTaskExplanationText";
import PosTaskTeaser from "./PosTaskTeaser";
import NewPosTaskReport from "./PosTaskTypes/NewPosTaskReport";
import NewPosTaskTME from "./PosTaskTypes/NewPosTaskTME";
import NewPosTaskTeacherFeedback from "./PosTaskTypes/NewPosTaskTeacherFeedback";
import NewPosTaskExercises from "./PosTaskTypes/NewPosTaskExercises";
import { useNavigate } from "react-router-dom";
import NewPreTaskActivity from "./PreTaskTypes/NewPreTaskActivity";
import NewPreTaskGame from "./PreTaskTypes/NewPreTaskGame";
import NewPreTaskWarmUp from "./PreTaskTypes/NewPreTaskWarmUp";
import NewPosTaskRepetition from "./PosTaskTypes/NewPosTaskRepetition";
import NewPosTaskInteractionActivity from "./PosTaskTypes/NewPosTaskInteractionActivity";
import NewPosTaskText from "./PosTaskTypes/NewPosTaskText";
import NewPosTaskAudio from "./PosTaskTypes/NewPosTaskAudio";
import NewPosTaskVideo from "./PosTaskTypes/NewPosTaskVideo";
import NewPosTaskDialog from "./PosTaskTypes/NewPosTaskDialog";
import NewPosTaskPhoto from "./PosTaskTypes/NewPosTaskPhoto";
import NewPosTaskGame from "./PosTaskTypes/NewPosTaskGame";
import { Media } from "../interfaces/Media";

export default function EditTask() {
    const params= useParams();
    const navigate = useNavigate();

    const [showNewPreTaskText, setShowNewPreTaskText] = useState(false);
    const [showNewPreTaskAudio, setShowNewPreTaskAudio] = useState(false);
    const [showNewPreTaskVideo, setShowNewPreTaskVideo] = useState(false);
    const [showNewPreTaskDialog, setShowNewPreTaskDialog] = useState(false);
    const [showNewPreTaskPhoto, setShowNewPreTaskPhoto] = useState(false);
    const [showNewPreTaskSpeech, setShowNewPreTaskSpeech] = useState(false);
    const [showNewPreTaskActivity, setShowNewPreTaskActivity] = useState(false);
    const [showNewPreTaskGame, setShowNewPreTaskGame] = useState(false);
    const [showNewPreTaskWarmUp, setShowNewPreTaskWarmUp] = useState(false);
    const [showNewPosTaskExplanationText, setShowNewPosTaskExplanationText] = useState(false);
    const [showNewPosTaskRepetition, setShowNewPosTaskRepetition] = useState(false);
    const [showNewPosTaskInteractionActivity, setShowNewPosTaskInteractionActivity] = useState(false);
    const [showNewPosTaskReport, setShowNewPosTaskReport] = useState(false);
    const [showNewPosTaskTME, setShowNewPosTaskTME] = useState(false);
    const [showNewPosTaskTeacherFeedback, setShowNewPosTaskTeacherFeedback] = useState(false);
    const [showNewPosTaskExercises, setShowNewPosTaskExercises] = useState(false);
    const [showNewPosTaskText, setShowNewPosTaskText] = useState(false);
    const [showNewPosTaskAudio, setShowNewPosTaskAudio] = useState(false);
    const [showNewPosTaskVideo, setShowNewPosTaskVideo] = useState(false);
    const [showNewPosTaskDialog, setShowNewPosTaskDialog] = useState(false);
    const [showNewPosTaskPhoto, setShowNewPosTaskPhoto] = useState(false);
    const [showNewPosTaskGame, setShowNewPosTaskGame] = useState(false);

    const [task, setTask] = useState<Task>({
        _id: '',
        name: '',
        pretasks: [],
        tasks: [],
        postasks: [],
        updated: new Date(),
        userId: '',
        active: false,
        authorship: '',
        aims: [],
        aim_other: '',
        specific_aim: '',
        proficiency_level: [],
        proficiency_level_comments: '',
        sessions: [],
        clonned: false,
        original_source: '',
        original_task: '',
        weight: 0
    });
    const [showTaskInfo, setShowTaskInfo] = useState(false);

    /* Pretask */
    const newPreTaskTextShow = () => {
        setShowNewPreTaskText(true);
    }

    const newPreTaskTextHide = () => {
        setShowNewPreTaskText(false);
    }

    const newPreTaskAudioShow = () => {
        setShowNewPreTaskAudio(true);
    }

    const newPreTaskVideoShow = () => {
        setShowNewPreTaskVideo(true);
    }

    const newPreTaskDialogShow = () => {
        setShowNewPreTaskDialog(true);
    }

    const newPreTaskPhotoShow = () => {
        setShowNewPreTaskPhoto(true);
    }

    const newPreTaskSpeechShow = () => {
        setShowNewPreTaskSpeech(true);
    }

    const newPreTaskActivityShow = () => {
        setShowNewPreTaskActivity(true);
    }

    const newPreTaskGameShow = () => {
        setShowNewPreTaskGame(true);
    }

    const newPreTaskWarmUpShow = () => {
        setShowNewPreTaskWarmUp(true);
    }

    const newPosTaskAudioShow = () => {
        setShowNewPosTaskAudio(true);
    }

    const newPosTaskDialogShow = () => {
        setShowNewPosTaskDialog(true);
    }

    const newPosTaskGameShow = () => {
        setShowNewPosTaskGame(true);
    }

    const newPosTaskPhotoShow = () => {
        setShowNewPosTaskPhoto(true);
    }

    const newPosTaskTextShow = () => {
        setShowNewPosTaskText(true);
    }

    const newPosTaskVideoShow = () => {
        setShowNewPosTaskVideo(true);
    }

    const newPosTaskRepetitionShow = () => {
        setShowNewPosTaskRepetition(true);
    }

    const newPosTaskInteractionActivityShow = () => {
        setShowNewPosTaskInteractionActivity(true);
    }

    const newPosTaskExplanationTextShow = () => {
        setShowNewPosTaskExplanationText(true);
    }

    const newPosTaskReportShow = () => {
        setShowNewPosTaskReport(true);
    }

    const newPosTaskTMEShow = () => {
        setShowNewPosTaskTME(true);
    }

    const newPosTaskTeacherFeedbackShow = () => {
        setShowNewPosTaskTeacherFeedback(true);
    }

    const newPosTaskExercisesShow = () => {
        setShowNewPosTaskExercises(true);
    }

    const newPreTaskAudioHide = () => {
        setShowNewPreTaskAudio(false);
    }

    const newPreTaskVideoHide = () => {
        setShowNewPreTaskVideo(false);
    }

    const newPreTaskDialogHide = () => {
        setShowNewPreTaskDialog(false);
    }

    const newPreTaskPhotoHide = () => {
        setShowNewPreTaskPhoto(false);
    }

    const newPreTaskSpeechHide = () => {
        setShowNewPreTaskSpeech(false);
    }

    const newPreTaskActivityHide = () => {
        setShowNewPreTaskActivity(false);
    }

    const newPreTaskGameHide = () => {
        setShowNewPreTaskGame(false);
    }

    const newPreTaskWarmUpHide = () => {
        setShowNewPreTaskWarmUp(false);
    }

    const newPosTaskAudioHide = () => {
        setShowNewPosTaskAudio(false);
    }

    const newPosTaskDialogHide = () => {
        setShowNewPosTaskDialog(false);
    }


    const newPosTaskGameHide = () => {
        setShowNewPosTaskGame(false);
    }


    const newPosTaskPhotoHide = () => {
        setShowNewPosTaskPhoto(false);
    }

    const newPosTaskTextHide = () => {
        setShowNewPosTaskText(false);
    }

    const newPosTaskVideoHide = () => {
        setShowNewPosTaskVideo(false);
    }

    const newPosTaskRepetitionHide = () => {
        setShowNewPosTaskRepetition(false);
    }

    const newPosTaskInteractionActivityHide = () => {
        setShowNewPosTaskInteractionActivity(false);
    }

    const newPosTaskExplanationTextHide = () => {
        setShowNewPosTaskExplanationText(false);
    }

    const newPosTaskReportHide = () => {
        setShowNewPosTaskReport(false);
    }

    const newPosTaskTMEHide = () => {
        setShowNewPosTaskTME(false);
    }

    const newPosTaskTeacherFeedbackHide = () => {
        setShowNewPosTaskTeacherFeedback(false);
    }

    const newPosTaskExercisesHide = () => {
        setShowNewPosTaskExercises(false);
    }

    const handleUpdatePretask = (pretask: PreTask) => {
        let newPreTask: any = {};
        switch (pretask.type) {
            case 'text':
                newPreTask = Object.assign({}, pretask);
                break;
            case 'audio':
                newPreTask = Object.assign({}, pretask);
                break; 
            case 'video':
                newPreTask = Object.assign({}, pretask);
                break; 
            case 'dialog':
                newPreTask = Object.assign({}, pretask);
                break;   
            case 'photo':
                newPreTask = Object.assign({}, pretask);
                break;   
            case 'speech':
                newPreTask = Object.assign({}, pretask);
                break;   
            case 'activity':
                newPreTask = Object.assign({}, pretask);
                break;   
            case 'game':
                newPreTask = Object.assign({}, pretask);
                break;   
            case 'warmup':
                newPreTask = Object.assign({}, pretask);
                break;       
            default:
                newPreTask = Object.assign({}, pretask);

        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(pretask)
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/pretask/' + pretask._id, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const newTask = Object.assign({}, data);
                setTask(newTask);
            });
    }

    const handleDeletePretask = (id: string) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/pretask/' + id, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then((data: Task) => {
                const newTask = Object.assign({}, data);
                setTask(newTask);
            });
    }

    const handleSortUpSubtask = (position: number) => {
        if (position > 0) {
            const newTask = Object.assign({}, task);
            const prevSubTask: SubTask = Object.assign({}, task.tasks[position - 1]);
            const currSubTask: SubTask = Object.assign({}, task.tasks[position]);
            newTask.tasks = task.tasks.map((pt: SubTask, index) => {
                if (index == position - 1) {
                    return (currSubTask);
                }
                if (index == position) {
                    return (prevSubTask);
                }
                return (pt);
            });

           const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newTask)
            };
            fetch('https://srv.taskgen.eu/v1/task/' + task._id, requestOptions)
                .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
                .then(data => {
                    const newTask = Object.assign({}, data);
                    setTask(newTask);
                });
        }
    }

    const handleSortDownSubtask = (position: number) => {
        if (position < task.tasks.length - 1) {
            const newTask = Object.assign({}, task);
            const prevSubTask: SubTask = Object.assign({}, task.tasks[position + 1]);
            const currSubTask: SubTask = Object.assign({}, task.tasks[position]);
            newTask.tasks = task.tasks.map((pt: SubTask, index) => {
                if (index == position + 1) {
                    return (currSubTask);
                }
                if (index == position) {
                    return (prevSubTask);
                }
                return (pt);
            });

           const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newTask)
            };
            fetch('https://srv.taskgen.eu/v1/task/' + task._id, requestOptions)
                .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
                .then(data => {
                    const newTask = Object.assign({}, data);
                    setTask(newTask);
                });
        }
    }

    const handleSortUpPretask = (position: number) => {
        if (position > 0) {
            const newTask = Object.assign({}, task);
            const prevPreTask: PreTask = Object.assign({}, task.pretasks[position - 1]);
            const currPreTask: PreTask = Object.assign({}, task.pretasks[position]);
            newTask.pretasks = task.pretasks.map((pt: PreTask, index) => {
                if (index == position - 1) {
                    return (currPreTask);
                }
                if (index == position) {
                    return (prevPreTask);
                }
                return (pt);
            });

           const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newTask)
            };
            fetch('https://srv.taskgen.eu/v1/task/' + task._id, requestOptions)
                .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
                .then(data => {
                    const newTask = Object.assign({}, data);
                    setTask(newTask);
                });
        }
    }

    const handleSortDownPretask = (position: number) => {
        if (position < task.pretasks.length - 1) {
            const newTask = Object.assign({}, task);
            const prevPreTask: PreTask = Object.assign({}, task.pretasks[position + 1]);
            const currPreTask: PreTask = Object.assign({}, task.pretasks[position]);
            newTask.pretasks = task.pretasks.map((pt: PreTask, index) => {
                if (index == position + 1) {
                    return (currPreTask);
                }
                if (index == position) {
                    return (prevPreTask);
                }
                return (pt);
            });

           const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newTask)
            };
            fetch('https://srv.taskgen.eu/v1/task/' + task._id, requestOptions)
                .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
                .then(data => {
                    const newTask = Object.assign({}, data);
                    setTask(newTask);
                });
        }
    }

    const handleSortUpPostask = (position: number) => {
        if (position > 0) {
            const newTask = Object.assign({}, task);
            const prevPosTask: PosTask = Object.assign({}, task.postasks[position - 1]);
            const currPosTask: PosTask = Object.assign({}, task.postasks[position]);
            newTask.postasks = task.postasks.map((pt: PosTask, index) => {
                if (index == position - 1) {
                    return (currPosTask);
                }
                if (index == position) {
                    return (prevPosTask);
                }
                return (pt);
            });

           const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newTask)
            };
            fetch('https://srv.taskgen.eu/v1/task/' + task._id, requestOptions)
                .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
                .then(data => {
                    const newTask = Object.assign({}, data);
                    setTask(newTask);
                });
        }
    }

    const handleSortDownPostask = (position: number) => {
        if (position < task.postasks.length - 1) {
            const newTask = Object.assign({}, task);
            const prevPosTask: PosTask = Object.assign({}, task.postasks[position + 1]);
            const currPosTask: PosTask = Object.assign({}, task.postasks[position]);
            newTask.postasks = task.postasks.map((pt: PosTask, index) => {
                if (index == position + 1) {
                    return (currPosTask);
                }
                if (index == position) {
                    return (prevPosTask);
                }
                return (pt);
            });

           const requestOptions = {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                },
                body: JSON.stringify(newTask)
            };
            fetch('https://srv.taskgen.eu/v1/task/' + task._id, requestOptions)
                .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
                .then(data => {
                    const newTask = Object.assign({}, data);
                    setTask(newTask);
                });
        }
    }

    const handleUpdatePosTask = (postask: PosTask) => {
        let newPosTask: any = {};
        switch (postask.type) {
            case 'repetition':
                newPosTask = Object.assign({}, postask);
                break;
            case 'interactionactivity':
                newPosTask = Object.assign({}, postask);
                break;
            case 'explanationtext':
                newPosTask = Object.assign({}, postask);
                break;
            case 'report':
                newPosTask = Object.assign({}, postask);
                break;  
            case 'tme':
                newPosTask = Object.assign({}, postask);
                break;   
            case 'teacherfeedback':
                newPosTask = Object.assign({}, postask);
                break;   
            case 'exercises':
                newPosTask = Object.assign({}, postask);
                break;  
            case 'text':
                newPosTask = Object.assign({}, postask);
                break;
            case 'audio':
                newPosTask = Object.assign({}, postask);
                break; 
            case 'video':
                newPosTask = Object.assign({}, postask);
                break; 
            case 'dialog':
                newPosTask = Object.assign({}, postask);
                break;   
            case 'photo':
                newPosTask = Object.assign({}, postask);
                break;   
            case 'game':
                newPosTask = Object.assign({}, postask);
                break;
            default:
                newPosTask = Object.assign({}, {
                    name: postask.name,
                    updated: postask.updated,
                    type: postask.type
                });

        }
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(postask)
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/postask/' + postask._id, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const newTask = Object.assign({}, data);
                setTask(newTask);
            });
    }

    const handleDeletePosTask = (id: string) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/postask/' + id, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then((data: Task) => {
                const newTask: Task = data;
                setTask(newTask);
            });
    }

    /* Subtask */
    const handleNewSubTask = () => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                name: 'New task',
                groups: [],
                roles: [],
                prompts: [],   
                goal: '',
                students: 0,
                grouping: '',
                studentsPerGroup: 0
            })
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/subtask', requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                let newTask = Object.assign({}, data);
                setTask(newTask);
            });
    }

    const handleCloneSubTask = (t: SubTask) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify({
                name: t.name + ' (copy)',
                groups: t.groups.map((g: SubTaskGroup) => { return g; }),
                roles: t.roles.map((r: SubTaskRole) => { return r; }),
                prompts: t.prompts.map((p: SubTaskPrompt) => { return p; }),  
                goal: t.goal,
                students: t.students,
                grouping: t.grouping,
                studentsPerGroup: t.studentsPerGroup
            })
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/subtask', requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                let newTask = Object.assign({}, data);
                setTask(newTask);
            });
    }

    const handleUpdateSubtask = (subtask: SubTask) => {
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(subtask)
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/subtask/' + subtask._id, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                const newTask = Object.assign({}, data);
                setTask(newTask);
            });
    }

    const handleDeleteSubtask = (id: string) => {
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/subtask/' + id, requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then((data: Task) => {
                const newTask: Task = data;
                setTask(newTask);
            });
    }

    const newPreTaskSave = (t: PreTask) => {
        let newPreTask: any = {};
        switch (t.type) {
            case 'text':
                newPreTask = Object.assign({}, t);
                break;
            case 'audio':
                newPreTask = Object.assign({}, t);
                break; 
            case 'video':
                newPreTask = Object.assign({}, t);
                break; 
            case 'dialog':
                newPreTask = Object.assign({}, t);
                break;   
            case 'photo':
                newPreTask = Object.assign({}, t);
                break;   
            case 'speech':
                newPreTask = Object.assign({}, t);
                break; 
            case 'activity':
                newPreTask = Object.assign({}, t);
                break;  
            case 'game':
                newPreTask = Object.assign({}, t);
                break;
            case 'warmup':
                newPreTask = Object.assign({}, t);
                break;
            default:
                newPreTask = Object.assign({}, t);

        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newPreTask)
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/pretask', requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                let newTask = Object.assign({}, data);
                setTask(newTask);
                setShowNewPreTaskText(false);
                setShowNewPreTaskAudio(false);
                setShowNewPreTaskVideo(false);
                setShowNewPreTaskDialog(false);
                setShowNewPreTaskPhoto(false);
                setShowNewPreTaskSpeech(false);
                setShowNewPreTaskActivity(false);
                setShowNewPreTaskGame(false);
                setShowNewPreTaskWarmUp(false);
            });
    }

    const handleClonePretask = (p: number) => {
        const newPreTask: PreTask = Object.assign({}, task.pretasks[p]);
        newPreTask._id = '';
        newPreTask.name = newPreTask.name + ' (copy)';
        newPreTask.updated = new Date();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newPreTask)
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/pretask', requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                let newTask = Object.assign({}, data);
                setTask(newTask);
                setShowNewPreTaskText(false);
                setShowNewPreTaskAudio(false);
                setShowNewPreTaskVideo(false);
                setShowNewPreTaskDialog(false);
                setShowNewPreTaskPhoto(false);
                setShowNewPreTaskSpeech(false);
                setShowNewPreTaskActivity(false);
                setShowNewPreTaskGame(false);
                setShowNewPreTaskWarmUp(false);
            });
    }

    const handleCloneAs = (id: string, direction: string) => {
        if (direction == 'pre-to-pos') {
            const preTaskIndex = task.pretasks.findIndex((t: PreTask) => t._id == id);
            if (preTaskIndex !== -1) {
                const preTask: PreTask = Object.assign({}, task.pretasks[preTaskIndex]);
                const newPosTask: PosTask = {
                    _id: '',
                    type: preTask.type,
                    name: preTask.name,
                    instructions: preTask.instructions,
                    text: preTask.text,
                    formatted_text: preTask.formatted_text,
                    media: preTask.media !== undefined ? preTask.media.map((m: Media) => { return m}) : [],
                    students: false,
                    teachers: false,
                    teacher_notes: preTask.teacher_notes,
                    updated: new Date(),
                    session: ''
                };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(newPosTask)
                };
                fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/postask', requestOptions)
                    .then(response => {
                        if (response.status !== 200) {
                            navigate('/');
                        }
                        return response.json();
                    })
                    .then(data => {
                        let newTask = Object.assign({}, data);
                        setTask(newTask);
                        alert('Pre-Task has been clonned as a Pos-Task.');
                    });
            }
        }

        if (direction == 'pos-to-pre') {
            const posTaskIndex = task.postasks.findIndex((t: PosTask) => t._id == id);
            if (posTaskIndex !== -1) {
                const posTask: PosTask = Object.assign({}, task.postasks[posTaskIndex]);
                const newPreTask: PosTask = {
                    _id: '',
                    type: posTask.type,
                    name: posTask.name,
                    instructions: posTask.instructions,
                    text: posTask.text,
                    formatted_text: posTask.formatted_text,
                    media: posTask.media !== undefined ? posTask.media.map((m: Media) => { return m}) : [],
                    students: false,
                    teachers: false,
                    teacher_notes: posTask.teacher_notes,
                    updated: new Date(),
                    session: ''
                };
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(newPreTask)
                };
                fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/pretask', requestOptions)
                    .then(response => {
                        if (response.status !== 200) {
                            navigate('/');
                        }
                        return response.json();
                    })
                    .then(data => {
                        let newTask = Object.assign({}, data);
                        setTask(newTask);
                        setShowNewPreTaskText(false);
                        setShowNewPreTaskAudio(false);
                        setShowNewPreTaskVideo(false);
                        setShowNewPreTaskDialog(false);
                        setShowNewPreTaskPhoto(false);
                        setShowNewPreTaskSpeech(false);
                        setShowNewPreTaskActivity(false);
                        setShowNewPreTaskGame(false);
                        setShowNewPreTaskWarmUp(false);
                        alert('Pos-Task has been clonned as a Pre-Task.');
                    });
            }
        }
    }

    const newPosTaskSave = (t: PosTask) => {
        let newPosTask: any = {};
        switch (t.type) {
            case 'repetition':
                newPosTask = Object.assign({}, t);
                break;
            case 'interactionactivity':
                newPosTask = Object.assign({}, t);
                break;
            case 'explanationtext':
                newPosTask = Object.assign({}, t);
                break;
            case 'report':
                newPosTask = Object.assign({}, t);
                break;  
            case 'tme':
                newPosTask = Object.assign({}, t);
                break;   
            case 'teacherfeedback':
                newPosTask = Object.assign({}, t);
                break;   
            case 'exercises':
                newPosTask = Object.assign({}, t);
                break; 
            case 'text':
                newPosTask = Object.assign({}, t);
                break;
            case 'audio':
                newPosTask = Object.assign({}, t);
                break; 
            case 'video':
                newPosTask = Object.assign({}, t);
                break; 
            case 'dialog':
                newPosTask = Object.assign({}, t);
                break;   
            case 'photo':
                newPosTask = Object.assign({}, t);
                break;   
            case 'game':
                newPosTask = Object.assign({}, t);
                break;
            default:
                newPosTask = Object.assign({}, {
                    name: t.name,
                    updated: t.updated,
                    type: t.type
                });

        }
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newPosTask)
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/postask', requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                let newTask = Object.assign({}, data);
                setTask(newTask);
                setShowNewPosTaskRepetition(false);
                setShowNewPosTaskInteractionActivity(false);
                setShowNewPosTaskExplanationText(false);
                setShowNewPosTaskReport(false);
                setShowNewPosTaskTME(false);
                setShowNewPosTaskTeacherFeedback(false);
                setShowNewPosTaskExercises(false);
                setShowNewPosTaskText(false);
                setShowNewPosTaskAudio(false);
                setShowNewPosTaskVideo(false);
                setShowNewPosTaskDialog(false);
                setShowNewPosTaskPhoto(false);
                setShowNewPosTaskGame(false);
            });
    }

    const handleClonePostask = (p: number) => {
        const newPosTask: PosTask = Object.assign({}, task.postasks[p]);
        newPosTask._id = '';
        newPosTask.name = newPosTask.name + ' (copy)';
        newPosTask.updated = new Date();
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newPosTask)
        };
        fetch('https://srv.taskgen.eu/v1/task/' + task._id + '/postask', requestOptions)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                let newTask = Object.assign({}, data);
                setTask(newTask);
                setShowNewPosTaskRepetition(false);
                setShowNewPosTaskInteractionActivity(false);
                setShowNewPosTaskExplanationText(false);
                setShowNewPosTaskReport(false);
                setShowNewPosTaskTME(false);
                setShowNewPosTaskTeacherFeedback(false);
                setShowNewPosTaskExercises(false);
                setShowNewPosTaskText(false);
                setShowNewPosTaskAudio(false);
                setShowNewPosTaskVideo(false);
                setShowNewPosTaskDialog(false);
                setShowNewPosTaskPhoto(false);
                setShowNewPosTaskGame(false);

            });
    }

    const handleNameChange = (e: any) => {
        const newTask = task;
        newTask.name = e.target.value;
        setTask(newTask);
    }

    const handleNameBlur = (e: any) => {
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(task)
        };
        fetch('https://srv.taskgen.eu/v1/task/change-name/' + task._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/task/' + params.id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                if (data.userId != localStorage.getItem('userId')) {
                    navigate('/');
                } else {
                    if (data.aims === undefined) {
                        data.aims = [];
                    }
                    if (data.proficiency_level === undefined) {
                        data.proficiency_level = [];
                    }
                    setTask(data);
                }
            });
    }, []);

    const nullSubmit = (e: any) => {
        e.preventDefault();
    }

    const toggleShowTaskInfo = () => {
        setShowTaskInfo(!showTaskInfo);
    }

    const handleAuthorshipChange = (e: any) => {
        const newTask = Object.assign({}, task);
        newTask.authorship = e.target.value;
        setTask(newTask);
    }

    const handleSaveProperties = (e: any) => {
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(task)
        };
        fetch('https://srv.taskgen.eu/v1/task/properties/' + task._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    const handleChangeGeneralAim = (e: any) => {
        const newTask = Object.assign({}, task);
        const index: number = newTask.aims.indexOf(parseInt(e.target.value));
        if (index === -1) {
            newTask.aims.push(parseInt(e.target.value));
        } else {
            newTask.aims = newTask.aims.filter((a: number) => a != parseInt(e.target.value));
        }
        setTask(newTask);
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newTask)
        };
        fetch('https://srv.taskgen.eu/v1/task/properties/' + newTask._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    const handleAimOtherChange = (e: any) => {
        const newTask = Object.assign({}, task);
        newTask.aim_other = e.target.value;
        setTask(newTask);
    }

    const handleChangeSpecificAim = (e: any) => {
        const newTask = Object.assign({}, task);
        newTask.specific_aim = e.target.value;
        setTask(newTask);
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newTask)
        };
        fetch('https://srv.taskgen.eu/v1/task/properties/' + newTask._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    const handleChangeProficiencyLevel = (e: any) => {
        const newTask = Object.assign({}, task);
        const index: number = newTask.proficiency_level.indexOf(parseInt(e.target.value));
        if (index === -1) {
            newTask.proficiency_level.push(parseInt(e.target.value));
        } else {
            newTask.proficiency_level = newTask.proficiency_level.filter((a: number) => a != parseInt(e.target.value));
        }
        setTask(newTask);
        const requestOptionsPost = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            },
            body: JSON.stringify(newTask)
        };
        fetch('https://srv.taskgen.eu/v1/task/properties/' + newTask._id, requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {});
    }

    const handleChangeProficiencyLevelComments = (e: any) => {
        const newTask = Object.assign({}, task);
        newTask.proficiency_level_comments = e.target.value;
        setTask(newTask);
    }

    const openOutput = () => {
        navigate('/preview-task/' + params.id);
    }

    return (
        <div className="page">
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="edit-task-container">
                                <div className="inner">
                                    <div className="task-data">
                                        <Form className="task-name-form" onSubmit={ nullSubmit }>
                                            <div className="form-field">
                                                <FormControl type="text" defaultValue={ task.name }
                                                    placeholder={ 'Escribe el nombre de la tarea' }
                                                    required={ true } name="name" onChange={ handleNameChange } onBlur={ handleNameBlur } />
                                                <a className="toggle-info" onClick={ toggleShowTaskInfo }>{ 'Task metadata' }</a>
                                            </div>
                                        </Form>
                                        <div className="task-more-options">
                                            <a className="output" title={ 'My design' } onClick={ openOutput }>{ 'My design' }</a>
                                        </div>
                                    </div>
                                    <div className={ 'task-info-elems-container' + (showTaskInfo ? ' show' : '')}>
                                        <div className="inner">
                                            <Form.Group className="field field-authorship">
                                                <Form.Label>{ 'Author(s)' }</Form.Label>
                                                <Form.Control type="text" name="authorship" required
                                                    value={ task.authorship } onChange={ handleAuthorshipChange } onBlur={ handleSaveProperties } />
                                            </Form.Group>
                                            <div className="aim-of-the-task">
                                                <Form.Group className="field field-aims">
                                                    <Form.Label>{ 'General aim of the task' }</Form.Label>
                                                    { aimsOfTask.map((a: any) => {
                                                        let checked = false;
                                                        if (task.aims !== undefined) {
                                                            const index: number = task.aims.indexOf(a.value);
                                                            if (index !== -1) {
                                                                checked = true;
                                                            }
                                                        }
                                                        return (
                                                            <Form.Check type="checkbox" id={ 'general-aim-' + a.value } 
                                                                key={ 'general-aim-' + a.value } value={ a.value }
                                                                checked={ checked }
                                                                onChange={ handleChangeGeneralAim } label={ a.description } />
                                                        )
                                                    })}
                                                    <Form.Control type="text" name="aim_other" required
                                                        value={ task.aim_other } onChange={ handleAimOtherChange } onBlur={ handleSaveProperties }
                                                        placeholder={ 'Other (please specify)' } />
                                                </Form.Group>
                                                <Form.Group className="field field-specific-aim">
                                                    <FormLabel htmlFor="specific_aim">{ 'Specific aim of the task' }</FormLabel>
                                                    <FormControl as="textarea" value={ task.specific_aim } name="goal" onChange={ handleChangeSpecificAim } onBlur={ handleSaveProperties } />
                                                    <div className="control-description">
                                                        { 'This information will be especially useful if you intend to share the task.' }
                                                    </div>
                                                </Form.Group>
                                            </div>
                                            <div className="proficiency-level-of-the-task">
                                                <Form.Group className="field field-proficiency-level">
                                                    <Form.Label>{ 'Student\'s proficiency level' }</Form.Label>
                                                    { proficiencyLevelsOfTask.map((a: any) => {
                                                        let checked = false;
                                                        if (task.proficiency_level !== undefined) {
                                                            const index: number = task.proficiency_level.indexOf(a.value);
                                                            if (index !== -1) {
                                                                checked = true;
                                                            }
                                                        }
                                                        return (
                                                            <Form.Check type="checkbox" id={ 'proficiency-level-' + a.value } 
                                                                key={ 'proficiency-level-' + a.value } value={ a.value }
                                                                checked={ checked }
                                                                onChange={ handleChangeProficiencyLevel} label={ a.description } />
                                                        )
                                                    })}
                                                </Form.Group>
                                                <Form.Group className="field field-proficiency-level-comments">
                                                    <FormLabel htmlFor="proficiency_level_comments">{ 'Comments' }</FormLabel> <Help id={ '654367be8fb78d09dce65ac6' } />
                                                    <FormControl as="textarea" value={ task.proficiency_level_comments } name="goal" 
                                                        onChange={ handleChangeProficiencyLevelComments } onBlur={ handleSaveProperties } />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lists">
                                        <div className="list pre-tasks">
                                            <div className="heading">
                                                <h2>{ 'Pre-Task' }</h2>
                                                <div className="heading-actions">
                                                    <DropdownButton as={ ButtonGroup } title={ 'New Pre-Task' }
                                                        id="newPreTask">
                                                        <Dropdown.Item eventKey="1" onClick={ newPreTaskTextShow } title={ 'Use a text for reading comprehension and to highlight language input' }>{ 'Text' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="2" onClick={ newPreTaskAudioShow } title={ 'Audios are great sources of input.' }>{ 'Audio' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="3" onClick={ newPreTaskVideoShow } title={ 'Videos are excellent sources of ‘multimodal’ input. They present together images, text, and audio in meaningful ways that facilitate second language learning.' }>{ 'Video' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="4" onClick={ newPreTaskDialogShow } title={ 'Reading comprehension dialogues provide an opportunity for both reading and speaking practice.' }>{ 'Written dialogue' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="5" onClick={ newPreTaskPhotoShow } title={ 'Photos provide excellent opportunities to generate communication and interaction.' }>{ 'Photo' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="6" onClick={ newPreTaskSpeechShow } title={ 'Video and audio transcripts provide exposure to rich and meaningful input that can be used in several ways.' }>{ 'Speech transcript' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="7" onClick={ newPreTaskActivityShow } title={ 'Interaction activities can be used in preparation for the main task. ' }>{ 'Interaction activity' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="8" onClick={ newPreTaskGameShow }>{ 'Game' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="9" onClick={ newPreTaskWarmUpShow } title={ 'Warm-ups at the beginning of a lesson may serve as a smooth introduction to the topic/content of the lesson, as an opportunity for learners to progressively switch to the second or foreign language, and as a way to get into the language lesson mood.' }>{ 'Warm-up' }</Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </div>
                                            <div className="contents">
                                                { task.pretasks.map((pt: PreTask, index) => {
                                                    return <PreTaskTeaser task_id={ task._id } pretask={ pt } position={ index } key={ 'st-' + index }
                                                        deleteCallback={ handleDeletePretask } updateCallback={ handleUpdatePretask }
                                                        cloneCallback={ handleClonePretask } cloneAsCallback={ handleCloneAs }
                                                        sortUpCallback={ handleSortUpPretask } sortDownCallback={ handleSortDownPretask }  />
                                                })}
                                            </div>
                                        </div>
                                        <div className="list tasks">
                                            <div className="heading">
                                                <h2>{ 'Main Task' } <Help id={ '650b0561b0f9abea03058849' } /></h2>
                                                <div className="heading-actions">
                                                    <button className="btn btn-primary" onClick={ handleNewSubTask }>
                                                        { 'New Main Task' }
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="contents">
                                                { task.tasks.map((st: SubTask, index) => {
                                                    return <SubTaskTeaser taskId={ task._id } subtask={ st } key={ 'st-' + st._id } position={ index }
                                                        updateCallback={ handleUpdateSubtask } deleteCallback={ handleDeleteSubtask }
                                                        cloneCallback={ handleCloneSubTask }
                                                        sortUpCallback={ handleSortUpSubtask } sortDownCallback={ handleSortDownSubtask } />
                                                })}
                                            </div>
                                        </div>
                                        <div className="list post-tasks">
                                            <div className="heading">
                                                <h2>{ 'Post-Task' }</h2>
                                                <div className="heading-actions">
                                                    <DropdownButton as={ ButtonGroup } title={ 'New Post-Task' }
                                                        id="newPosTask">
                                                        <Dropdown.Item eventKey="1" onClick={ newPosTaskRepetitionShow } title={ 'Task repetition is known to have a positive impact on all aspects of production and learning.' }>{ 'Task repetition' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="2" onClick={ newPosTaskInteractionActivityShow } title={ 'Interaction activities can be used in preparation for the main task. ' }>{ 'Interaction activity' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="3" onClick={ newPosTaskExplanationTextShow } title={ 'Explicit explanations can help solve or consolidate language issues that came up during task performance.' }>{ 'Explicit explanation' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="4" onClick={ newPosTaskReportShow }>{ 'Written to Oral / Oral to Written' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="5" onClick={ newPosTaskTMEShow }>{ 'Task model and exercises' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="6" onClick={ newPosTaskTeacherFeedbackShow }>{ 'Teacher feedback' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="7" onClick={ newPosTaskExercisesShow }>{ 'Exercises' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="8" onClick={ newPosTaskTextShow } title={ 'Use a text for reading comprehension and to highlight language input' }>{ 'Text' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="9" onClick={ newPosTaskAudioShow } title={ 'Audios are great sources of input.' }>{ 'Audio' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="10" onClick={ newPosTaskVideoShow } title={ 'Videos are excellent sources of ‘multimodal’ input. They present together images, text, and audio in meaningful ways that facilitate second language learning.' }>{ 'Video' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="11" onClick={ newPosTaskDialogShow } title={ 'Reading comprehension dialogues provide an opportunity for both reading and speaking practice.' }>{ 'Written dialogue' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="12" onClick={ newPosTaskPhotoShow } title={ 'Photos provide excellent opportunities to generate communication and interaction.' }>{ 'Photo' }</Dropdown.Item>
                                                        <Dropdown.Item eventKey="13" onClick={ newPosTaskGameShow }>{ 'Game' }</Dropdown.Item>
                                                    </DropdownButton>
                                                </div>
                                            </div>
                                            <div className="contents">
                                                {task.postasks.map((pt: PosTask, index) => {
                                                    return <PosTaskTeaser task_id={ task._id } postask={ pt } position={ index } key={ 'st-' + pt._id }
                                                        updateCallback={ handleUpdatePosTask } deleteCallback={ handleDeletePosTask }
                                                        cloneCallback={ handleClonePostask } cloneAsCallback={ handleCloneAs }
                                                        sortUpCallback={ handleSortUpPostask } sortDownCallback={ handleSortDownPostask }  />
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Pre tasks */}
            { showNewPreTaskText && <NewPreTaskText saveForm={ newPreTaskSave } closeForm={ newPreTaskTextHide } /> }
            { showNewPreTaskAudio && <NewPreTaskAudio saveForm={ newPreTaskSave } closeForm={ newPreTaskAudioHide } /> }
            { showNewPreTaskVideo && <NewPreTaskVideo saveForm={ newPreTaskSave } closeForm={ newPreTaskVideoHide } /> }
            { showNewPreTaskDialog && <NewPreTaskDialog saveForm={ newPreTaskSave } closeForm={ newPreTaskDialogHide } /> }
            { showNewPreTaskPhoto && <NewPreTaskPhoto saveForm={ newPreTaskSave } closeForm={ newPreTaskPhotoHide } /> }
            { showNewPreTaskSpeech && <NewPreTaskSpeech saveForm={ newPreTaskSave } closeForm={ newPreTaskSpeechHide } /> }
            { showNewPreTaskActivity && <NewPreTaskActivity saveForm={ newPreTaskSave } closeForm={ newPreTaskActivityHide } /> }
            { showNewPreTaskGame && <NewPreTaskGame saveForm={ newPreTaskSave } closeForm={ newPreTaskGameHide } /> }
            { showNewPreTaskWarmUp && <NewPreTaskWarmUp saveForm={ newPreTaskSave } closeForm={ newPreTaskWarmUpHide } /> }
            {/* Post tasks */}
            { showNewPosTaskRepetition && <NewPosTaskRepetition saveForm={ newPosTaskSave } closeForm={ newPosTaskRepetitionHide } /> }
            { showNewPosTaskInteractionActivity && <NewPosTaskInteractionActivity saveForm={ newPosTaskSave } closeForm={ newPosTaskInteractionActivityHide } /> }
            { showNewPosTaskExplanationText && <NewPosTaskExplanationText saveForm={ newPosTaskSave } closeForm={ newPosTaskExplanationTextHide } /> }
            { showNewPosTaskReport && <NewPosTaskReport saveForm={ newPosTaskSave } closeForm={ newPosTaskReportHide } /> }
            { showNewPosTaskTME && <NewPosTaskTME saveForm={ newPosTaskSave } closeForm={ newPosTaskTMEHide } /> }
            { showNewPosTaskTeacherFeedback && <NewPosTaskTeacherFeedback saveForm={ newPosTaskSave } closeForm={ newPosTaskTeacherFeedbackHide } /> }
            { showNewPosTaskExercises && <NewPosTaskExercises saveForm={ newPosTaskSave } closeForm={ newPosTaskExercisesHide } /> }
            { showNewPosTaskText && <NewPosTaskText saveForm={ newPosTaskSave } closeForm={ newPosTaskTextHide } /> }
            { showNewPosTaskAudio && <NewPosTaskAudio saveForm={ newPosTaskSave } closeForm={ newPosTaskAudioHide } /> }
            { showNewPosTaskVideo && <NewPosTaskVideo saveForm={ newPosTaskSave } closeForm={ newPosTaskVideoHide } /> }
            { showNewPosTaskDialog && <NewPosTaskDialog saveForm={ newPosTaskSave } closeForm={ newPosTaskDialogHide } /> }
            { showNewPosTaskPhoto && <NewPosTaskPhoto saveForm={ newPosTaskSave } closeForm={ newPosTaskPhotoHide } /> }
            { showNewPosTaskGame && <NewPosTaskGame saveForm={ newPosTaskSave } closeForm={ newPosTaskGameHide } /> }
        </div>
    );
}