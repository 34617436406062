import { useEffect, useState } from "react";
import PrimaryHeader from "../../header/PrimaryHeader";
import SecondaryHeader from "../../header/SecondaryHeader";
import { Button, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { emptyTaskExample, Task, TaskExample } from "../../interfaces/Task";
import TaskExampleAdd from "./TaskExampleAdd";
import TaskExampleDelete from "./TaskExampleDelete";

export default function TaskExamples() {
    const navigate = useNavigate();
    const isAdmin = localStorage.getItem('isAdmin');
    
    const [taskExamples, setTaskExamples] = useState<TaskExample[]>([]);
    const [selectedTaskExample, setSelectedTaskExample] = useState<TaskExample>(emptyTaskExample);
    const [showAddForm, setShowAddForm] = useState(false);
    const [showEditForm, setShowEditForm] = useState(false);
    const [showDeleteForm, setShowDeleteForm] = useState(false);

    useEffect(() => {
        const requestOptionsPost = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }
        };
        fetch('https://srv.taskgen.eu/v1/examples', requestOptionsPost)
            .then(response => {
                if (response.status !== 200) {
                    navigate('/');
                }
                return response.json();
            })
            .then(data => {
                setTaskExamples(data);
            });
    }, [showAddForm]);

    const handleShowAddForm = () => {
        setShowAddForm(true);
    }

    const handleHideAddForm = () => {
        setShowAddForm(false);
    }

    const handleSumbitAddForm = (te: TaskExample) => {
        const newTaksExamples = taskExamples.map((taskexample: TaskExample) => { return taskexample; })
        newTaksExamples.push(te);
        setTaskExamples(newTaksExamples);
        setShowAddForm(false);
    }
    
    const handleShowDeleteForm = (event: any, id: string) => {
        event.preventDefault()
        const newSelectedTaskExample: TaskExample = taskExamples.find((t: TaskExample) => t._id == id) ?? emptyTaskExample;
        if (newSelectedTaskExample._id != '') {
            setSelectedTaskExample(newSelectedTaskExample);
            setShowDeleteForm(true);
        } else {
            alert('No task example selected selected.');
        }
    }
    
    const handleHideDeleteForm = () => {
        setShowDeleteForm(false);
    }

    const handleSumbitDeleteForm = (id: string) => {
        const newTaskExamples = taskExamples.filter((te: TaskExample) => te._id != id);
        setTaskExamples(newTaskExamples);
        setShowDeleteForm(false);
    }

    const handleSortUp = (event: any, position: number) => {
        event.preventDefault();
        if (position > 0) {
            const newTaskExamples: TaskExample[] = taskExamples.map((pt: TaskExample, index) => {
                let t: TaskExample = Object.assign({}, pt); 
                t.weight = index;
                if (index == position - 1) {
                    t.weight = position;
                    
                }
                if (index == position) {
                    t.weight = position - 1;
                }
                return (t);
            });
            newTaskExamples.sort((a: TaskExample, b: TaskExample) => a.weight - b.weight);
            setTaskExamples(newTaskExamples);
            
            for (let i = 0; i < newTaskExamples.length; i++) {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(newTaskExamples[i])
                };
                fetch('https://srv.taskgen.eu/v1/examples/update/' + newTaskExamples[i]._id, requestOptions)
                    .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                }).then(data => {});
            }
        }
    }

    const handleSortDown = (event: any, position: number) => {
        event.preventDefault();
        if (position < taskExamples.length - 1) {
            const newTaskExamples: TaskExample[] = taskExamples.map((pt: TaskExample, index) => {
                let t: TaskExample = Object.assign({}, pt); 
                t.weight = index;
                if (index == position + 1) {
                    t.weight = position;
                }
                if (index == position) {
                    t.weight = position + 1;
                }
                return (t);
            });
            newTaskExamples.sort((a: TaskExample, b: TaskExample) => a.weight - b.weight);
            setTaskExamples(newTaskExamples);

            for (let i = 0; i < newTaskExamples.length; i++) {
                const requestOptions = {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    },
                    body: JSON.stringify(newTaskExamples[i])
                };
                fetch('https://srv.taskgen.eu/v1/examples/update/' + newTaskExamples[i]._id, requestOptions)
                    .then(response => {
                    if (response.status !== 200) {
                        navigate('/');
                    }
                    return response.json();
                }).then(data => {});
            }
        }
    }

    return (
        <div className="page">
            <SecondaryHeader />
            <div className="page-content">
                <PrimaryHeader />
                <div className="page-content-inner">
                    <div className="row">
                        { isAdmin &&
                            <div className="admin-taskexamples-container">
                                <h1>Task Examples management</h1>
                                <div className="page-actions">
                                    <Button onClick={ handleShowAddForm }>{ 'New Task Example' }</Button>
                                </div>
                                <div className="admin-taskexamples-table-container">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Id</th>
                                                <th>Title</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { taskExamples.map((te: TaskExample, index) => {
                                                return(
                                                    <tr key={ 'taskexample-' + te._id }>
                                                        <td>{ te._id }</td>
                                                        <td>{ te.task.name }</td>
                                                        <td className="actions">
                                                            <a className="sort-up" title={ 'Move up' } href="#" onClick={ (event) => handleSortUp(event, index) }></a>
                                                            <a className="sort-down" title={ 'Move down' } href="#" onClick={ (event) => handleSortDown(event, index) }></a>
                                                            <a className="delete" title={ 'Delete task example' } href="#" onClick={ (event) => handleShowDeleteForm(event, te._id) }></a>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                        </Table>
                                </div>
                            </div>
                        }
                        { !isAdmin && 
                            <div className="admin-taskexamples-container">
                                { 'You are not allowed to access this secion.' }
                            </div>
                        }
                    </div>
                </div>
            </div>
            { showAddForm &&
                <TaskExampleAdd submitCallback={ handleSumbitAddForm } closeCallback={ handleHideAddForm } />
            }
            {showDeleteForm &&
                <TaskExampleDelete taskExample={ selectedTaskExample } submitCallback={ handleSumbitDeleteForm } closeCallback={ handleHideDeleteForm } />
            }
        </div>);
}